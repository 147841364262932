/* eslint-disable import/no-extraneous-dependencies */
import { OvaBackupProvider } from 'blues-corejs/dist'
import { OvaBackupProviderProto } from './grpc-imports'

export class OvaBackupProviderTransformer {
  static #ovaBackupProviderProtoMapping: Record<
    OvaBackupProvider,
    OvaBackupProviderProto
  > = {
    [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
    [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
    [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
    [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
    [OvaBackupProvider.VERITAS_NET_BACKUP]:
      OvaBackupProviderProto.VERITAS_NET_BACKUP,
  }

  static #ovaBackupProviderMapping: Record<
    OvaBackupProviderProto,
    OvaBackupProvider
  > = {
    [OvaBackupProviderProto.COHESITY]: OvaBackupProvider.COHESITY,
    [OvaBackupProviderProto.COMMVAULT]: OvaBackupProvider.COMMVAULT,
    [OvaBackupProviderProto.RUBRIK]: OvaBackupProvider.RUBRIK,
    [OvaBackupProviderProto.VEEAM]: OvaBackupProvider.VEEAM,
    [OvaBackupProviderProto.VERITAS_NET_BACKUP]:
      OvaBackupProvider.VERITAS_NET_BACKUP,
  }

  static transformToProtoDefinition(
    provider: OvaBackupProvider
  ): OvaBackupProviderProto {
    return this.#ovaBackupProviderProtoMapping[provider]
  }

  static transformToEnumDefinition(
    provider: OvaBackupProviderProto
  ): OvaBackupProvider {
    return this.#ovaBackupProviderMapping[provider]
  }
}
