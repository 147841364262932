/* eslint-disable import/no-extraneous-dependencies */
import { AbstractListRequest } from '@lib/clients/list-request'
import {
  ListOvaServersRequestPb,
  OvaBackupProviderProto,
} from '@lib/clients/grpc-imports'
import { OvaBackupProvider } from 'blues-corejs/dist'

export enum OvaServerStatus {
  CLEAN = 0,
  INFECTED = 1,
}

export interface ListOvaServersParams {
  accountIdsList?: Array<string>
  backupProvidersList?: Array<OvaBackupProvider>
  statusesList?: Array<OvaServerStatus>
  malwareThreatNames?: Set<string>
  ransomwareThreatNames?: Set<string>
  textSearch?: string
}

export class ListOvaServersFilter extends AbstractListRequest<ListOvaServersRequestPb> {
  #requestParams: Partial<ListOvaServersParams>

  constructor(requestParams: Partial<ListOvaServersParams>) {
    super()
    this.#requestParams = requestParams
  }

  createGrpcRequest(): ListOvaServersRequestPb {
    const grpcRequest = new ListOvaServersRequestPb()

    const initial = new ListOvaServersRequestPb.Initial()

    const filters = this.#processFilters()

    initial.setFilters(filters)

    initial.setPageSize(AbstractListRequest.DEFAULT_PAGE_SIZE)

    return grpcRequest.setInitial(initial)
  }

  #processFilters(): ListOvaServersRequestPb.Initial.Filters {
    const filters = new ListOvaServersRequestPb.Initial.Filters()

    const {
      accountIdsList,
      backupProvidersList,
      statusesList,
      textSearch,
      malwareThreatNames,
      ransomwareThreatNames,
    } = this.#requestParams

    if (accountIdsList && accountIdsList.length) {
      filters.setAccountIdsList(accountIdsList)
    }

    if (backupProvidersList && backupProvidersList.length) {
      filters.setBackupProvidersList(
        backupProvidersList.map((backupProvider) =>
          this.#convertBackupProviderEnumToProto(backupProvider)
        )
      )
    }

    if (this.#isFilterByThreatsPresent && statusesList?.length) {
      filters.setStatusesList(this.#convertStatusesListToProto(statusesList))
    }

    if (malwareThreatNames?.size || ransomwareThreatNames?.size) {
      filters.setStatusesList([])
    }

    if (malwareThreatNames && malwareThreatNames.size) {
      filters.setMalwareThreatNamesList(Array.from(malwareThreatNames))
    }

    if (ransomwareThreatNames && ransomwareThreatNames.size) {
      filters.setRansomwareThreatNamesList(Array.from(ransomwareThreatNames))
    }

    if (this.#isTextSearchValid()) {
      filters.setTextSearch(textSearch as string)
    }

    return filters
  }

  #convertBackupProviderEnumToProto(
    backupProvider: OvaBackupProvider
  ): OvaBackupProviderProto {
    const mapping: Record<OvaBackupProvider, OvaBackupProviderProto> = {
      [OvaBackupProvider.VEEAM]: OvaBackupProviderProto.VEEAM,
      [OvaBackupProvider.COMMVAULT]: OvaBackupProviderProto.COMMVAULT,
      [OvaBackupProvider.RUBRIK]: OvaBackupProviderProto.RUBRIK,
      [OvaBackupProvider.COHESITY]: OvaBackupProviderProto.COHESITY,
      [OvaBackupProvider.VERITAS_NET_BACKUP]:
        OvaBackupProviderProto.VERITAS_NET_BACKUP,
    }

    return mapping[backupProvider]
  }

  #convertStatusesListToProto(
    statusesList: Array<OvaServerStatus>
  ): Array<ListOvaServersRequestPb.Initial.Filters.Status> {
    const mapping: Record<
      OvaServerStatus,
      ListOvaServersRequestPb.Initial.Filters.Status
    > = {
      [OvaServerStatus.CLEAN]:
        ListOvaServersRequestPb.Initial.Filters.Status.CLEAN,
      [OvaServerStatus.INFECTED]:
        ListOvaServersRequestPb.Initial.Filters.Status.INFECTED,
    }

    return statusesList.map((status) => mapping[status])
  }

  #isTextSearchValid(): boolean {
    return !!this.#requestParams.textSearch
  }

  get #isFilterByThreatsPresent(): boolean {
    const { malwareThreatNames, ransomwareThreatNames } = this.#requestParams

    return Boolean(malwareThreatNames?.size || ransomwareThreatNames?.size)
  }
}
